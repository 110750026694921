<template>
	<div class="o-row o-row--lg">
		<div class="o-container">
			<div class="c-exam">
				<h1>Exam</h1>
				<h2>Dit is het examen zelf</h2>
			</div>
		</div>
	</div>
</template>

<script src="./Exam.controller.js"></script>
<style lang="scss" src="./Exam.styles.scss"></style>
